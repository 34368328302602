<template>
  <div class="workshop-keyword-recs pb-5">
    <fluency-loader v-if="loading"></fluency-loader>
    <template v-else-if="recommendations">
      <div class="px-4 mx-3 mb-4 mt-4">
        <h4 class="mb-4 text-info">There Might Be Some Room For Improvement</h4>
        <div class="text-black-50">See what you think. Choose to accept if you want to give it a shot. If not, that's okay. Choosing to reject is better than taking no action at all.</div>
      </div>
      <div class="d-flex justify-content-end mx-3 position-relative">
        <b-button v-if="!hideGoBack" variant="ghost-secondary" class="mr-auto" @click="$store.commit('notifications/customExperience', '')">Go Back</b-button>
        <div style="min-height: 32px;">
          <transition name="fade">
            <div v-if="selected.length > 0" class="d-flex align-items-center">
              <span class="mr-3">{{selected.length}} Recommendation{{selected.length > 1 ? 's' : ''}} selected</span>
              <fluency-loader v-if="miniLoading" dots color></fluency-loader>
              <b-button-group v-else>
                <b-button variant="outline-success" class="accept-btn" @click="applyRecs(true)">Accept</b-button>
                <b-button variant="outline-secondary" class="reject-btn" @click="applyRecs(false)">Reject</b-button>
              </b-button-group>
            </div>
          </transition>
        </div>
        <span v-if="selected.length === 0" class="small font-italic text-muted position-absolute" style="bottom: 0;">Select rows to accept or reject.</span>
      </div>

      <fluent-table :items="recommendations"
                    :fields="fields"
                    :use-flex-classes="false"
                    selected-row-override="notificationKeywordRecs"
                    primary-key="analysisRecommendationId">

        <template v-slot:cell(details)="row">

          <div class="d-flex align-items-center mb-3">
            <template v-if="row.item.analysisAction === 'ADD'">
              <fluency-icon type="add" class="text-add mr-2"></fluency-icon>
              <span>[{{row.item.analysisDataParsed.searchTerm}}]</span>
            </template>
            <template v-if="row.item.analysisAction === 'REMOVE'">
              <fluency-icon type="subtract" class="text-remove mr-2"></fluency-icon>
              <span>{{keyword(row.item.analysisDataParsed.keywordText, row.item.analysisDataParsed.matchType)}}</span>
            </template>
            <template v-if="row.item.analysisAction === 'REDUCE_BID'">
              <fluency-icon type="caretDown" class="mt-1 text-reduce"></fluency-icon>
              <span class="text-reduce font-weight-bold mr-2">$</span>
              <span>{{keyword(row.item.analysisDataParsed.keywordText, row.item.analysisDataParsed.matchType)}}</span>
            </template>
            <template v-if="row.item.analysisAction === 'INCREASE_BID'">
              <fluency-icon type="caretUp" class="text-increase"></fluency-icon>
              <span class="text-increase font-weight-bold mr-2">$</span>
              <span>{{keyword(row.item.analysisDataParsed.keywordText, row.item.analysisDataParsed.matchType)}}</span>
            </template>
          </div>
          <div class="white-space-no-wrap overflow-hidden text-overflow-ellipsis" v-p-tooltip.top="row.item.analysisDataParsed.campaignName">
            <span class="font-weight-bold">Campaign:</span>
            <span>{{row.item.analysisDataParsed.campaignName}}</span>
          </div>
          <div class="white-space-no-wrap overflow-hidden text-overflow-ellipsis" v-p-tooltip.top="row.item.analysisDataParsed.adGroupName">
            <span class="font-weight-bold">Ad Group:</span>
            <span>{{row.item.analysisDataParsed.adGroupName}}</span>
          </div>
          <t-button-inline @click="row.toggleDetails()"><fluency-icon :type="row.item._showDetails ? 'chevronDown' : 'chevronRight'" class="mr-2" />Supporting Data</t-button-inline>

        </template>

        <template v-slot:row-details="row">
          <div class="pb-5 px-5 pt-2 text-left">
              <div v-for="(value, key) in row.item.analysisDataParsed" :key="key">
                <div v-if="!key.includes('Id')"  class="px-3 row-details-row d-flex">
                  <!--KEY-->
                  <span v-if="key === 'keywordText' && row.item.analysisAction === 'ADD'" style="min-width: 145px;">
                    Originally Matched On:
                  </span>
                  <span v-else style="min-width: 145px;">
                    {{$filters.convertFromCamelCase(key)}}:
                  </span>

                  <!--VALUE-->
                  <span>
                    {{dynamicFilter(value, key)}}
                  </span>

                </div>
                <div v-if="key === 'matchType' && row.item.analysisAction === 'ADD'" :key="`proposed${key}`" class="d-flex px-3 row-details-row">
                  <span style="min-width: 145px;">Proposed Match Type:</span><span>EXACT</span>
                </div>

              </div>
          </div>
        </template>

      </fluent-table>

    </template>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import _ from 'lodash'
export default {
  name: 'NotificationKeywordRecs',
  components: { FluencyLoader },
  props: {
    hideGoBack: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      miniLoading: false,
      recommendations: undefined,
      staged: [],
      fields: [
        { slot: 'selectRow', sticky: false, defaultWidth: 26, tdAttr: { style: 'vertical-align: middle;' } },
        { key: 'details', label: 'Recommendation', defaultWidth: 'auto', sortable: false, tdClass: 'py-4' },
        { key: 'rationale', label: 'Rationale', defaultWidth: 'auto', sortable: false, tdClass: 'py-4' }
      ]
    }
  },
  computed: {
    plan () {
      const notification = this.$store.getters['notifications/workshopNotification']
      if (notification) return notification.accountPlan
      const settingsItem = this.$store.getters.settingsItem
      if (settingsItem) return settingsItem
      const activeItem = this.$store.getters.activeItem
      if (activeItem) return activeItem
      return null
    },
    dirty () {
      return false
    },
    selected () {
      return this.$store.getters['table/selectedRowObjs']('notificationKeywordRecs') || []
    }
  },
  watch: {
    plan: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal?.accountPlanId !== oldVal?.accountPlanId) {
          this.fetchRecommendations()
        }
      }
    },
    recommendations (val) {
      if (val?.length === 0) {
        this.$emit('finished')
      }
    }
  },
  methods: {
    async fetchRecommendations () {
      this.loading = true

      const payload = {
        accountPlan: {
          accountPlanId: this.plan?.accountPlanId,
          customerId: this.plan?.customerId
        },
        recommendations: {
          REMOVE: 1,
          REDUCE_BID: 1,
          ADD: 1,
          INCREASE_BID: 1
        }
      }
      const recommendations = await this.$res.fetch.keywordAnalysis(payload)

      if (recommendations && !_.isEmpty(recommendations)) {
        let parsedRecs = Object.values(recommendations)
          .reduce((accumulator, current) => [...accumulator, ...current], [])
          .map(rec => {
            const analysisDataParsed = JSON.parse(rec.analysisData?.replace(/\n\s\t/g, '') || '{}')
            return {
              ...rec,
              analysisDataParsed
            }
          })
        if (this.plan.campaignPlanId || this.plan.adGroupPlanId) {
          parsedRecs = parsedRecs.filter(rec => rec.analysisDataParsed.campaignPlanId === this.plan.campaignPlanId && (!this.plan.adGroupPlanId || rec.analysisDataParsed.adGroupPlanId === this.plan.adGroupPlanId))
        }

        this.recommendations = parsedRecs
      } else {
        this.$emit('error')
      }
      this.loading = false
    },
    async applyRecs (accept) {
      this.miniLoading = true
      const ids = this.selected.map(rec => rec.analysisRecommendationId)
      const payload = {
        toApply: accept ? ids : [],
        toIgnore: !accept ? ids : []
      }
      const response = await this.$res.set.executeRecommendations(payload)
      if (response) {
        const message = accept ? 'Recommendations Accepted' : 'Recommendations Rejected'
        this.$toast(message, 'success')
        this.$setCompat(this, 'recommendations', this.recommendations.filter(rec => !ids.includes(rec.analysisRecommendationId)))
      } else {
        this.$emit('error')
      }
      this.miniLoading = false
    },
    dynamicFilter (value, key) {
      switch (key) {
        case 'bid':
        case 'cost':
        case 'cpc':
        case 'topOfPageCpc':
        case 'firstPositionCpc':
          return this.$filters.currency(value, 2)
        case 'ctr':
          return this.$filters.percentage(value)
        default:
          return value
      }
    },
    keyword (text, matchType = 'BROAD') {
      switch (matchType) {
        case 'BROAD':
          return text
        case 'PHRASE':
          return `"${text}"`
        case 'EXACT':
          return `[${text}]`
        default:
          return text
      }
    }
  }
}
</script>

<style lang="scss">
.workshop-keyword-recs {
  .text-add {
    color: $fluency-green;
  }
  .text-increase {
    color: $fluency-blue;
  }
  .text-reduce {
    color: darken($fluency-yellow, 10);
  }
  .text-remove {
    color: $fluency-pink
  }
  .row-details-row:nth-child(odd) {
    background-color: $fluency-lightergray;
  }
}
</style>
